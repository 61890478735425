import './App.scss';
import googlePlayLogo from './assets/images/google-play-badge.png';
import appStoreLogo from './assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import companyLogo from './assets/images/Logo_512_Transparent.png';

function App() {
  return (
    <div className="app container-fluid">
      <header className="row">
          <div className="col-md-3 d-flex justify-content-center align-items-center p-3">
            <img className="company-logo" src={companyLogo} alt="Pickle Cube Software Ltd Logo" />
          </div>

          <div className="showcase col-md-9 p-3 d-flex flex-column justify-content-between align-items-stretch" >
              <h2 className="text-center">RETRO FALL</h2>
              <img className="d-block place-holder mx-auto m-3" src="https://storage.googleapis.com/pcs-cdn-bucket/v1.0_preview.gif" alt="Retro Fall Gameplay"/>
              <div className="m-n3 p-3 store-link-container d-flex justify-content-center align-items-center gap-5">
                <a href='https://play.google.com/store/apps/details?id=software.picklecube.retrofall'>
                  <img className="android" src={googlePlayLogo} alt="Download on Google Play Store" />
                </a>
                <a href='https://apps.apple.com/us/app/retro-fall/id6443681675'>
                  <img className="apple" src={appStoreLogo} alt="Download on Apple AppStore" />
                </a>
              </div>
          </div>
      </header>
      <footer className="row">
        <div className="col-12 company-info">
            <span className="d-block title">Pickle Cube Software Ltd.</span>
            <div className="mt-1"></div>
            <span className="d-block">1b Blackfriars House</span>
            <span className="d-block">Parsonage</span>
            <span className="d-block">Manchester</span>
            <span className="d-block">England</span>
            <span className="d-block">M3 2JA</span>
            <div className="mt-1"></div>
            <span className="d-block">Registered in England and Wales</span>
            <span className="d-block">Registration No. 14344207</span>
            <div className="mt-2"></div>
            <span className="d-block contact">Contact can be made on the above address</span>
            <span className="d-block contact">or electronically via <a href="mailto://contact@picklecube.software">contact@picklecube.software</a></span>
        </div>
      </footer>
    </div>
  );
}

export default App;
